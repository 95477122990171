import { injectable, injectRequired } from '../../../di'
import { TenantTeamState } from '../states/tenant-team'
import { TenantTeamResource } from '../resources/tenant-team'
import type {
  TenantTeam,
  TenantTeamCreateRequest,
  TenantTeamMembersUpdateRequest,
  TenantTeamUpdateRequest
} from 'app-model.carbon-saver'
import errorHandler from '../../../errorHandler'
import type { TenantTeamExt } from '../use/tenant-team'
import { TenantMemberState } from '../states/tenant-member'

export class TenantTeamService {
  public static injectable = injectable(TenantTeamService)

  private readonly state: TenantTeamState
  private readonly resource: TenantTeamResource
  private readonly memberState: TenantMemberState

  constructor () {
    this.resource = injectRequired(TenantTeamResource)
    this.state = injectRequired(TenantTeamState)
    this.memberState = injectRequired(TenantMemberState)
  }

  async addTenantTeam (tenantTeam: TenantTeamCreateRequest): Promise<TenantTeamExt> {
    const createdTenantTeam = await this.resource.addTenantTeam(tenantTeam)
    const tenantTeamExt: TenantTeamExt = await this.loadTenantTeamExt(createdTenantTeam)

    this.state.tenantTeams.addTenantTeam(tenantTeamExt)
    this.memberState.addTenantTeam(tenantTeamExt)

    return tenantTeamExt
  }

  async deleteTenantTeam (tenantTeam: Pick<TenantTeam, 'id'>): Promise<TenantTeam> {
    const tenantTeamExt = this.state.tenantTeams.getTenantTeam(tenantTeam)

    const removedTenantTeam = await this.resource.deleteTenantTeam(tenantTeam.id)

    this.state.tenantTeams.removeTenantTeam(removedTenantTeam)
    if (tenantTeamExt !== undefined) {
      this.memberState.removeTenantTeam(tenantTeamExt)
    }

    return removedTenantTeam
  }

  async batchDeleteTenantTeams (tenantTeams: Array<Pick<TenantTeam, 'id'>>): Promise<TenantTeam[]> {
    const deletedTeams: TenantTeam[] = []
    for (const tenantTeam of tenantTeams) {
      try {
        const deletedTeam = await this.deleteTenantTeam(tenantTeam)
        deletedTeams.push(deletedTeam)
        this.state.tenantTeams.removeTenantTeam(deletedTeam)
      } catch (error) {
        errorHandler(error)
      }
    }

    return deletedTeams
  }

  async modifyTenantTeam (tenantTeam: Pick<TenantTeam, 'id'> & TenantTeamUpdateRequest): Promise<TenantTeamExt> {
    const existingTeamExt = this.state.tenantTeams.getTenantTeam(tenantTeam)

    const modifiedTenantTeam = await this.resource.modifyTenantTeam(tenantTeam.id, tenantTeam)
    const tenantTeamExt: TenantTeamExt = existingTeamExt !== undefined ? { ...existingTeamExt, ...modifiedTenantTeam } : await this.loadTenantTeamExt(modifiedTenantTeam)

    this.state.tenantTeams.updateTenantTeam(tenantTeamExt)

    return tenantTeamExt
  }

  async modifyTenantTeamMembers (tenantTeam: Pick<TenantTeam, 'id'> & TenantTeamMembersUpdateRequest): Promise<TenantTeamExt> {
    const existingTeamExt = this.state.tenantTeams.getTenantTeam(tenantTeam)

    const modifiedTenantTeam = await this.resource.modifyTenantTeamMembers(tenantTeam.id, tenantTeam)
    const tenantTeamExt = await this.loadTenantTeamExt(modifiedTenantTeam)

    this.state.tenantTeams.updateTenantTeam(tenantTeamExt)
    if (existingTeamExt !== undefined) {
      await this.memberState.removeTenantTeam(existingTeamExt)
    }
    await this.memberState.addTenantTeam(tenantTeamExt)

    return tenantTeamExt
  }

  async addTenantTeamMembers (tenantTeamMembers: Pick<TenantTeam, 'id'> & TenantTeamMembersUpdateRequest): Promise<TenantTeam> {
    const tenantTeam = await this.resource.addTenantTeamMembers(tenantTeamMembers.id, { members: tenantTeamMembers.members.map(m => ({ id: m.id })) })
    const tenantTeamExt = await this.loadTenantTeamExt(tenantTeam)

    this.state.tenantTeams.updateTenantTeam(tenantTeamExt)
    for (const member of tenantTeamMembers.members) {
      const tenantMemberExt = this.memberState.getTenantMember(member)
      if (tenantMemberExt !== undefined) {
        const teamIndex = tenantMemberExt.teams.findIndex((item) => item.id === tenantTeam.id)
        if (teamIndex !== 0) {
          tenantMemberExt.teams.push(tenantTeam)
        }
      }
    }

    return tenantTeam
  }

  async deleteTenantTeamMembers (tenantTeamMembers: Pick<TenantTeam, 'id'> & TenantTeamMembersUpdateRequest): Promise<TenantTeam> {
    const tenantTeam = await this.resource.deleteTenantTeamMembers(tenantTeamMembers.id, { members: tenantTeamMembers.members.map(m => ({ id: m.id })) })
    const tenantTeamExt = await this.loadTenantTeamExt(tenantTeam)

    this.state.tenantTeams.updateTenantTeam(tenantTeamExt)
    for (const member of tenantTeamMembers.members) {
      const tenantMemberExt = this.memberState.getTenantMember(member)
      if (tenantMemberExt !== undefined) {
        const teamIndex = tenantMemberExt.teams.findIndex((item) => item.id === tenantTeam.id)
        if (teamIndex > -1) {
          tenantMemberExt.teams.splice(teamIndex, 1)
        }
      }
    }

    return tenantTeam
  }

  async loadTenantTeamExt (tenantTeam: TenantTeam): Promise<TenantTeamExt> {
    const membersData = await this.resource.getMembers({ ids: [tenantTeam.id] })
    const members = membersData[tenantTeam.id]

    return {
      ...tenantTeam,
      members
    }
  }
}
