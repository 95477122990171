import type {
  Page,
  TenantMember,
  TenantMemberCreateRequest,
  TenantMemberEnabledUpdateRequest,
  TenantMemberLastSeenRequest,
  TenantMemberLastSeenResponse,
  TenantMemberRolesUpdateRequest,
  TenantMemberUpdateRequest
} from 'app-model.carbon-saver'
import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'

export class TenantMemberResource {
  public static injectable = injectable(TenantMemberResource)

  async listTenantMembers (): Promise<Page<TenantMember>> {
    const response = await axiosApi.get('/tenant-member')
    return response.data
  }

  async listTenantMembersDisabled (): Promise<Page<TenantMember>> {
    const response = await axiosApi.get('/tenant-member/disabled')
    return response.data
  }

  async addTenantMember (data: TenantMemberCreateRequest): Promise<TenantMember> {
    const response = await axiosApi.post('/tenant-member', data)
    return response.data
  }

  async deleteTenantMember (tenantMemberId: string): Promise<TenantMember> {
    const response = await axiosApi.delete(`/tenant-member/${tenantMemberId}`)
    return response.data
  }

  async modifyTenantMemberRoles (tenantMemberId: string, data: TenantMemberRolesUpdateRequest): Promise<TenantMember> {
    const response = await axiosApi.put(`/tenant-member/${tenantMemberId}/roles`, data)
    return response.data
  }

  async modifyTenantMemberEnabled (tenantMemberId: string, data: TenantMemberEnabledUpdateRequest): Promise<TenantMember> {
    const response = await axiosApi.put(`/tenant-member/${tenantMemberId}/enabled`, data)
    return response.data
  }

  async getLastSeen (data: TenantMemberLastSeenRequest): Promise<TenantMemberLastSeenResponse> {
    const response = await axiosApi.post('/tenant-member/last-seen', data)
    return response.data
  }

  async modifyTenantMember (tenantMemberId: string, data: TenantMemberUpdateRequest): Promise<TenantMember> {
    const response = await axiosApi.put(`/tenant-member/${tenantMemberId}`, data)
    return response.data
  }
}
