import { injectable, injectRequired } from '../../../di'
import type { UseTenantTeam } from '../use/tenant-team'
import { useTenantTeam } from '../use/tenant-team'
import { TenantTeamResource } from '../resources/tenant-team'

export class TenantTeamState {
  public static injectable = injectable(TenantTeamState)

  public readonly tenantTeams: UseTenantTeam

  constructor () {
    this.tenantTeams = useTenantTeam({
      resource: injectRequired(TenantTeamResource)
    })
  }
}
