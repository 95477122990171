import { injectable, injectRequired } from '../../../di'
import { TenantMemberResource } from '../resources/tenant-member'
import { TenantMemberState } from '../states/tenant-member'
import type { TenantMember, TenantMemberRole } from 'app-model.carbon-saver'
import type { TenantMemberExt } from '../use/tenant-member'
import { TenantTeamResource } from '../resources/tenant-team'
import errorHandler from '../../../errorHandler'
import { TenantTeamState } from '../states/tenant-team'

export class TenantMemberService {
  public static injectable = injectable(TenantMemberService)

  private readonly resource: TenantMemberResource
  private readonly teamResource: TenantTeamResource
  private readonly state: TenantMemberState
  private readonly teamState: TenantTeamState

  constructor () {
    this.resource = injectRequired(TenantMemberResource)
    this.teamResource = injectRequired(TenantTeamResource)
    this.state = injectRequired(TenantMemberState)
    this.teamState = injectRequired(TenantTeamState)
  }

  async enableTenantMember (tenantMember: Pick<TenantMemberExt, 'id'>): Promise<TenantMemberExt> {
    const existingMemberExt = this.state.tenantMembers.getTenantMember(tenantMember) ?? this.state.tenantMembersDisabled.getTenantMember(tenantMember)

    const member = await this.resource.modifyTenantMemberEnabled(tenantMember.id, { enabled: true })
    const memberExt: TenantMemberExt = existingMemberExt !== undefined ? { ...existingMemberExt, ...member } : await this.loadTenantMemberExt(member)

    this.state.tenantMembersDisabled.removeTenantMember(member)
    this.state.tenantMembers.addTenantMember(memberExt)

    return memberExt
  }

  async batchEnableTenantMembers (tenantMembers: Array<Pick<TenantMember, 'id'>>): Promise<TenantMemberExt[]> {
    const members: TenantMemberExt[] = []
    for (const tenantMember of tenantMembers) {
      members.push(await this.enableTenantMember(tenantMember))
    }
    return members
  }

  async disableTenantMember (tenantMember: Pick<TenantMember, 'id'>): Promise<TenantMemberExt> {
    const existingMemberExt = this.state.tenantMembers.getTenantMember(tenantMember) ?? this.state.tenantMembersDisabled.getTenantMember(tenantMember)

    const member = await this.resource.modifyTenantMemberEnabled(tenantMember.id, { enabled: false })
    const memberExt: TenantMemberExt = existingMemberExt !== undefined ? { ...existingMemberExt, ...member } : await this.loadTenantMemberExt(member)

    this.state.tenantMembers.removeTenantMember(memberExt)
    this.state.tenantMembersDisabled.addTenantMember(memberExt)

    return memberExt
  }

  async batchDisableTenantMembers (tenantMembers: Array<Pick<TenantMember, 'id'>>): Promise<TenantMemberExt[]> {
    const members: TenantMemberExt[] = []
    for (const tenantMember of tenantMembers) {
      try {
        members.push(await this.disableTenantMember(tenantMember))
      } catch (error) {
        errorHandler(error)
      }
    }
    return members
  }

  async modifyRole (tenantMember: Pick<TenantMember, 'id' | 'roles'>): Promise<TenantMemberExt> {
    const existingMemberExt = this.state.tenantMembers.getTenantMember(tenantMember) ?? this.state.tenantMembersDisabled.getTenantMember(tenantMember)

    const member = await this.resource.modifyTenantMemberRoles(tenantMember.id, { roles: tenantMember.roles })
    const memberExt: TenantMemberExt = existingMemberExt !== undefined ? { ...existingMemberExt, ...member } : await this.loadTenantMemberExt(member)

    this.state.tenantMembers.updateTenantMember(memberExt)
    this.state.tenantMembersDisabled.updateTenantMember(memberExt)

    return memberExt
  }

  async batchModifyRole (tenantMembers: TenantMemberExt[], roles: TenantMemberRole[]): Promise<TenantMemberExt[]> {
    const members: TenantMemberExt[] = []
    for (const tenantMember of tenantMembers) {
      try {
        members.push(await this.modifyRole({ ...tenantMember, roles }))
      } catch (error) {
        errorHandler(error)
      }
    }
    return members
  }

  async addTenantMember (tenantMember: Required<Pick<TenantMemberExt, 'email' | 'firstName' | 'lastName' | 'teams' | 'roles'>>): Promise<TenantMemberExt> {
    const createdMember = await this.resource.addTenantMember({
      email: tenantMember.email,
      firstName: tenantMember.firstName,
      lastName: tenantMember.lastName,
      roles: tenantMember.roles
    })

    for (const team of tenantMember.teams) {
      await this.teamResource.addTenantTeamMembers(team.id, { members: [{ id: createdMember.id }] })

      const tenantTeam = this.teamState.tenantTeams.getTenantTeam(team.id)
      if (tenantTeam !== undefined) {
        tenantTeam.members.push(createdMember)
      }
    }

    const createdMemberExt = await this.loadTenantMemberExt(createdMember)

    this.state.tenantMembers.addTenantMember(createdMemberExt)

    return createdMemberExt
  }

  async deleteTenantMember (tenantMember: Pick<TenantMember, 'id'>): Promise<TenantMember> {
    const existingMemberExt = this.state.tenantMembers.getTenantMember(tenantMember) ?? this.state.tenantMembersDisabled.getTenantMember(tenantMember)

    const member = await this.resource.deleteTenantMember(tenantMember.id)

    for (const team of existingMemberExt?.teams ?? []) {
      const tenantTeam = this.teamState.tenantTeams.getTenantTeam(team)
      if (tenantTeam !== undefined) {
        const index = tenantTeam.members.findIndex(m => m.id === member.id)
        if (index > -1) {
          tenantTeam.members.splice(index, 1)
        }
      }
    }

    this.state.tenantMembers.removeTenantMember(member)
    this.state.tenantMembersDisabled.removeTenantMember(member)

    return member
  }

  async modifyTenantMember (tenantMember: Required<Pick<TenantMemberExt, 'id' | 'email' | 'firstName' | 'lastName' | 'teams'>>): Promise<TenantMemberExt> {
    const existingMemberExt = this.state.tenantMembers.getTenantMember(tenantMember) ?? this.state.tenantMembersDisabled.getTenantMember(tenantMember)

    const member = await this.resource.modifyTenantMember(tenantMember.id, {
      email: tenantMember.email,
      firstName: tenantMember.firstName,
      lastName: tenantMember.lastName
    })

    const existingTeams = existingMemberExt?.teams ?? []
    const finalTeams = tenantMember.teams

    const toDeleteTeams = existingTeams.filter(t => !finalTeams.includes(t))
    const toAddTeams = finalTeams.filter(t => !existingTeams.includes(t))

    for (const team of toDeleteTeams) {
      await this.teamResource.deleteTenantTeamMembers(team.id, { members: [{ id: member.id }] })
      const tenantTeam = this.teamState.tenantTeams.getTenantTeam(team)
      if (tenantTeam !== undefined) {
        const index = tenantTeam.members.findIndex(m => m.id === member.id)
        if (index > -1) {
          tenantTeam.members.splice(index, 1)
        }
      }
    }

    for (const team of toAddTeams) {
      await this.teamResource.addTenantTeamMembers(team.id, { members: [{ id: member.id }] })
      const tenantTeam = this.teamState.tenantTeams.getTenantTeam(team)
      if (tenantTeam !== undefined) {
        tenantTeam.members.push(member)
      }
    }

    const memberExt: TenantMemberExt = existingMemberExt !== undefined ? { ...existingMemberExt, ...member, ...{ teams: tenantMember.teams } } : await this.loadTenantMemberExt(member)

    this.state.tenantMembers.updateTenantMember(memberExt)
    this.state.tenantMembersDisabled.updateTenantMember(memberExt)

    return memberExt
  }

  private async loadTenantMemberExt (createdMember: TenantMember): Promise<TenantMemberExt> {
    const lastSeenData = await this.resource.getLastSeen({ ids: [createdMember.id] })
    const teamsData = await this.teamResource.getMemberTeams({ ids: [createdMember.id] })
    const lastSeen = lastSeenData[createdMember.id]
    const teams = teamsData[createdMember.id]

    return {
      ...createdMember,
      lastSeen,
      teams
    }
  }
}
