import type { TenantMemberResource } from '../resources/tenant-member'
import type { TenantMember, TenantTeam } from 'app-model.carbon-saver'
import type { TenantTeamResource } from '../resources/tenant-team'
import type { TenantTeamExt } from './tenant-team'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { refDefault } from '@vueuse/core'

export interface TenantMemberExt extends TenantMember {
  lastSeen?: {
    time: number
    duration: number
  }
  teams: TenantTeam[]
}

export interface UseTenantMember {
  membersOptional: Ref<TenantMemberExt[] | undefined>
  members: Ref<TenantMemberExt[]>
  listMembers: UseAsyncRunner<TenantMemberExt[]>
  getTenantMember: (id: string | Pick<TenantMemberExt, 'id'>) => TenantMemberExt | undefined
  addTenantMember: (tenantMember: TenantMemberExt) => void
  updateTenantMember: (tenantMember: Pick<TenantMemberExt, 'id'> & Partial<TenantMemberExt>) => void
  removeTenantMember: (tenantMember: Pick<TenantMemberExt, 'id'>) => void
  addTenantTeam: (tenantTeam: TenantTeamExt) => void
  removeTenantTeam: (tenantTeam: Pick<TenantTeamExt, 'id' | 'members'>) => void
}

export interface UseTenantMembersOptions {
  resource: TenantMemberResource
  teamResource: TenantTeamResource
  disabledMembers?: boolean
}

export function useTenantMembers (options: UseTenantMembersOptions): UseTenantMember {
  const { resource, teamResource } = options

  const membersOptional = ref<TenantMemberExt[]>()
  const members = refDefault(membersOptional, [])

  const listMembers = useAsyncRunner(
    async (): Promise<TenantMemberExt[]> => {
      const tenantMembersPage = options?.disabledMembers === true ? await resource.listTenantMembersDisabled() : await resource.listTenantMembers()

      const ids = tenantMembersPage.items.map((member) => member.id)

      const lastSeen = await resource.getLastSeen({ ids })
      const teams = await teamResource.getMemberTeams({ ids })

      return tenantMembersPage.items.map(tenantMember => ({
        ...tenantMember,
        lastSeen: lastSeen[tenantMember.id],
        teams: teams[tenantMember.id]
      }))
    }, membersOptional)

  const getTenantMember = (id: string | Pick<TenantMember, 'id'>): TenantMemberExt | undefined => {
    return members.value.find((item) => typeof id === 'object' ? item.id === id.id : item.id === id)
  }

  const addTenantMember = (tenantMember: TenantMemberExt): void => {
    members.value = [tenantMember, ...members.value]
  }

  const updateTenantMember = (tenantMember: Pick<TenantMemberExt, 'id'> & Partial<TenantMemberExt>): void => {
    const index = members.value.findIndex(item => item.id === tenantMember.id)
    if (index > -1) {
      const existingTenantMember = members.value[index]
      const items = [...members.value]

      const updatedTenantMember: TenantMemberExt = { ...existingTenantMember, ...tenantMember }
      items.splice(index, 1, updatedTenantMember)

      members.value = [...items]
    }
  }

  const removeTenantMember = (tenantMember: Pick<TenantMemberExt, 'id'>): void => {
    const index = members.value.findIndex((item) => item.id === tenantMember.id)
    if (index > -1) {
      const items = [...members.value]
      items.splice(index, 1)

      members.value = items
    }
  }

  const addTenantTeam = (tenantTeam: TenantTeamExt): void => {
    for (const tenantMember of tenantTeam.members) {
      const index = members.value.findIndex((item) => item.id === tenantMember.id)
      if (index > -1) {
        const existingTenantMember = members.value[index]
        existingTenantMember.teams.push(tenantTeam)

        members.value.splice(index, 1, existingTenantMember)
      }
    }
  }

  const removeTenantTeam = (tenantTeam: Pick<TenantTeamExt, 'id' | 'members'>): void => {
    for (const tenantMember of tenantTeam.members) {
      const index = members.value.findIndex((item) => item.id === tenantMember.id)
      if (index > -1) {
        const existingTenantMember = members.value[index]
        const teamIndex = existingTenantMember.teams.findIndex((item) => item.id === tenantTeam.id)
        if (teamIndex > -1) {
          existingTenantMember.teams.splice(teamIndex, 1)
        }

        members.value.splice(index, 1, existingTenantMember)
      }
    }
  }

  return {
    members,
    membersOptional,
    listMembers,
    getTenantMember,
    addTenantMember,
    updateTenantMember,
    removeTenantMember,
    addTenantTeam,
    removeTenantTeam
  }
}
