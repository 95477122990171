import type { TenantMember, TenantTeam } from 'app-model.carbon-saver'
import type { TenantTeamResource } from '../resources/tenant-team'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { refDefault } from '@vueuse/core'

export interface TenantTeamExt extends TenantTeam {
  members: TenantMember[]
}

export interface UseTenantTeam {
  teamsOptional: Ref<TenantTeamExt[] | undefined>
  teams: Ref<TenantTeamExt[]>
  listTeams: UseAsyncRunner<TenantTeamExt[]>
  getTenantTeam: (id: string | Pick<TenantTeamExt, 'id'>) => TenantTeamExt | undefined
  addTenantTeam: (tenantTeam: TenantTeamExt) => void
  updateTenantTeam: (tenantTeam: TenantTeamExt) => void
  removeTenantTeam: (tenantTeam: Pick<TenantTeamExt, 'id'>) => void
}

export interface UseTenantTeamOptions {
  resource: TenantTeamResource
}

export function useTenantTeam (options: UseTenantTeamOptions): UseTenantTeam {
  const { resource } = options

  const teamsOptional = ref<TenantTeamExt[]>()
  const teams = refDefault(teamsOptional, [])

  const listTeams = useAsyncRunner(
    async (): Promise<TenantTeamExt[]> => {
      const tenantTeamsPage = await resource.listTenantTeams()

      const ids = tenantTeamsPage.items.map((team) => team.id)
      const members = await resource.getMembers({ ids })

      return tenantTeamsPage.items.map(tenantTeam => ({
        ...tenantTeam,
        members: members[tenantTeam.id]
      }))
    },
    teams)

  const getTenantTeam = (id: string | Pick<TenantTeamExt, 'id'>): TenantTeamExt | undefined => {
    return teams.value.find((item) => typeof id === 'object' ? item.id === id.id : item.id === id)
  }

  const addTenantTeam = (tenantTeam: TenantTeamExt): void => {
    teams.value = [tenantTeam, ...teams.value]
  }

  const updateTenantTeam = (tenantTeam: TenantTeamExt): void => {
    const index = teams.value.findIndex(item => item.id === tenantTeam.id)
    if (index > -1) {
      const newList = [...teams.value]
      newList.splice(index, 1, tenantTeam)

      teams.value = newList
    }
  }

  const removeTenantTeam = (tenantTeam: Pick<TenantTeamExt, 'id'>): void => {
    const index = teams.value.findIndex((item) => item.id === tenantTeam.id)
    if (index > -1) {
      const newList = [...teams.value]
      newList.splice(index, 1)

      teams.value = newList
    }
  }

  return {
    teamsOptional,
    teams,
    listTeams,
    getTenantTeam,
    addTenantTeam,
    updateTenantTeam,
    removeTenantTeam
  }
}
