import type {
  Page,
  TenantMemberTeamsRequest,
  TenantMemberTeamsResponse,
  TenantTeam,
  TenantTeamCreateRequest,
  TenantTeamMembersUpdateRequest,
  TenantTeamMembersRequest,
  TenantTeamMembersResponse,
  TenantTeamUpdateRequest
} from 'app-model.carbon-saver'
import { axiosApi } from '../../../resources'
import { injectable } from '../../../di'

export class TenantTeamResource {
  public static injectable = injectable(TenantTeamResource)

  async listTenantTeams (): Promise<Page<TenantTeam>> {
    const response = await axiosApi.get('/tenant-team')
    return response.data
  }

  async addTenantTeam (data: TenantTeamCreateRequest): Promise<TenantTeam> {
    const response = await axiosApi.post('/tenant-team', data)
    return response.data
  }

  async deleteTenantTeam (teamId: string): Promise<TenantTeam> {
    const response = await axiosApi.delete(`/tenant-team/${teamId}`)
    return response.data
  }

  async getMembers (data: TenantTeamMembersRequest): Promise<TenantTeamMembersResponse> {
    const response = await axiosApi.post('/tenant-team/members', data)
    return response.data
  }

  async modifyTenantTeam (tenantTeamId: string, data: TenantTeamUpdateRequest): Promise<TenantTeam> {
    const response = await axiosApi.put(`/tenant-team/${tenantTeamId}`, data)
    return response.data
  }

  async modifyTenantTeamMembers (tenantTeamId: string, data: TenantTeamMembersUpdateRequest): Promise<TenantTeam> {
    const response = await axiosApi.put(`/tenant-team/${tenantTeamId}/members`, data)
    return response.data
  }

  async getMemberTeams (data: TenantMemberTeamsRequest): Promise<TenantMemberTeamsResponse> {
    const response = await axiosApi.post('/tenant-team/member-teams', data)
    return response.data
  }

  async addTenantTeamMembers (tenantTeamId: string, data: TenantTeamMembersUpdateRequest): Promise<TenantTeam> {
    const response = await axiosApi.post(`/tenant-team/${tenantTeamId}/add-members`, data)
    return response.data
  }

  async deleteTenantTeamMembers (tenantTeamId: string, data: TenantTeamMembersUpdateRequest): Promise<TenantTeam> {
    const response = await axiosApi.post(`/tenant-team/${tenantTeamId}/delete-members`, data)
    return response.data
  }
}
