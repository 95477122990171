import { injectable, injectRequired } from '../../../di'
import { TenantMemberResource } from '../resources/tenant-member'
import type { TenantMemberExt, UseTenantMember } from '../use/tenant-member'
import { useTenantMembers } from '../use/tenant-member'
import { TenantTeamResource } from '../resources/tenant-team'
import type { TenantTeamExt } from '../use/tenant-team'

export class TenantMemberState {
  public static injectable = injectable(TenantMemberState)

  public readonly tenantMembers: UseTenantMember
  public readonly tenantMembersDisabled: UseTenantMember

  constructor () {
    this.tenantMembers = useTenantMembers({
      resource: injectRequired(TenantMemberResource),
      teamResource: injectRequired(TenantTeamResource),
      disabledMembers: false
    })

    this.tenantMembersDisabled = useTenantMembers({
      resource: injectRequired(TenantMemberResource),
      teamResource: injectRequired(TenantTeamResource),
      disabledMembers: true
    })
  }

  getTenantMember (tenantMember: Pick<TenantMemberExt, 'id'>): TenantMemberExt | undefined {
    return this.tenantMembers.getTenantMember(tenantMember) ?? this.tenantMembersDisabled.getTenantMember(tenantMember)
  }

  addTenantTeam (tenantTeam: TenantTeamExt): void {
    this.tenantMembers.addTenantTeam(tenantTeam)
    this.tenantMembersDisabled.addTenantTeam(tenantTeam)
  }

  removeTenantTeam (tenantTeam: Pick<TenantTeamExt, 'id' | 'members'>): void {
    this.tenantMembers.removeTenantTeam(tenantTeam)
    this.tenantMembersDisabled.removeTenantTeam(tenantTeam)
  }
}
